:root {
  --corder-radius: 2px;
}

html {
  scroll-behavior: smooth;
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.t-heading, h1, h2 {
  font-family: Playfair Display, serif;
}

.s-body {
  margin: 16px 0;
  line-height: 1.5;
  display: block;
}

p {
  margin: 1rem 0;
}

main {
  padding-bottom: 2rem;
}

div#container {
  max-width: 80rem;
  margin: 0 auto;
}

div#wrapper {
  grid-template-columns: repeat(auto-fit, minmax(min(28rem, 100%), 1fr));
  gap: 1rem;
  display: grid;
}

section {
  background-color: #fff;
  padding: 1rem;
}

@media screen and (width <= 559px) {
  div#container, section {
    padding: .5rem;
  }
}

section .glide img {
  object-fit: cover;
  width: 100%;
}

section#welcome {
  color: #fff;
  text-align: center;
  background-image: url("herobg.021c3e64.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: column wrap;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: min(90vh, 40rem);
  padding-bottom: 64px;
  display: flex;
}

section#welcome button {
  width: min(20rem, 80%);
}

section#welcome .s-body {
  max-width: 36rem;
}

@media screen and (width >= 560px) {
  main {
    background-image: linear-gradient(#0000, #00000080 36rem), url("herobg.021c3e64.jpg");
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  div#container {
    padding: 0 1rem;
  }

  section#welcome {
    background-color: #0000;
    background-image: none;
  }
}

section#about {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

section#directions {
  flex-flow: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 1rem;
  display: flex;
}

section#points-of-interest {
  flex-shrink: 0;
  flex-basis: 100%;
  margin: 1rem 0;
}

button {
  color: #fff;
  background-color: #0000002e;
  border: 1px solid silver;
  padding: 16px 8px;
}

.input-address input {
  width: 100%;
  padding: .5rem;
}

.input-address button {
  width: 100%;
}

#map {
  background-color: silver;
  flex: 100%;
  width: 100%;
  min-height: 60vh;
}

.poi-list {
  margin-top: 1rem;
}

.poi-item {
  flex-flow: row;
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.poi-item-details {
  flex-flow: column;
  gap: .5rem;
  display: flex;
}

.poi-item img {
  max-width: 5rem;
}

#addr-search {
  z-index: 10;
  background: #00000080;
  padding: 1rem;
  position: fixed;
  inset: 0;
}

#addr-search .modal-content {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

#addr-search .modal-header {
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

#turn-by-turn {
  border: 1px solid silver;
  padding: .5rem;
}

#turn-by-turn.closed #map-panel {
  display: none;
}

#map-panel {
  max-height: 40vh;
  overflow: scroll;
}

#turn-by-turn .tbt-header {
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

a#find-me {
  border-radius: var(--corder-radius);
  color: #000;
  border: 1px solid silver;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
}

img.icon {
  max-width: 32px;
}

img.icon.small {
  max-width: 24px;
}

div.label {
  text-align: center;
  padding: 0 .5rem;
}

#locations-filter {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  margin: 1rem 0;
  display: flex;
}

#locations-filter a {
  border-radius: var(--corder-radius);
  border: 1px solid silver;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

#locations-filter img.icon {
  margin-right: .25rem;
}

#location-entries {
  grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  gap: 1rem;
  display: grid;
}

.location-entry {
  border-radius: var(--corder-radius);
  border: 1px solid #e2e2e2;
  padding: 1rem;
  box-shadow: 0 2px 8px #dadada;
}

.location-name {
  font-weight: bold;
}

.type-all {
  color: #000;
}

.location-type {
  font-size: 86%;
}

.type-tourism {
  color: green;
}

.type-guest_house {
  color: red;
}

.type-gas_station {
  color: #00f;
}

.type-restaurant {
  color: orange;
}

div.funding {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media screen and (width >= 560px) {
  div.funding {
    flex-flow: row;
  }
}

div.funding a {
  margin-top: .5rem;
  display: block;
}

div.funding img {
  max-height: 6rem;
  display: block;
}

section#project {
  margin: 1rem 0;
}

section#project div.funding {
  flex-flow: row;
  justify-content: center;
  margin: 1rem 0;
}
/*# sourceMappingURL=index.1d21bbfb.css.map */
