:root {
  --corder-radius: 2px;
}

html {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.t-heading,
h1,
h2 {
  font-family: 'Playfair Display', serif;
}

.s-body {
  display: block;
  margin: 16px 0;
  line-height: 1.5;
}

p {
  margin: 1rem 0;
}

main {
  padding-bottom: 2rem;
}

div#container {
  max-width: 80rem;
  margin: 0 auto;
}

div#wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(28rem, 100%), 1fr));
  gap: 1rem;
}

section {
  padding: 1rem;

  background-color: white;
}

@media screen and (max-width: 559px) {

  div#container,
  section {
    padding: 0.5rem;
  }
}

section .glide img {
  width: 100%;
  object-fit: cover;
}

section#welcome {
  height: min(90vh, 40rem);
  background-image: url("../assets/herobg.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
  text-align: center;

  width: 100%;

  display: flex;
  flex-flow: column wrap;
  justify-content: end;
  align-items: center;

  gap: 1rem;

  padding-bottom: 64px;
}

section#welcome button {
  width: min(20rem, 80%);
}

section#welcome .s-body {
  max-width: 36rem;
}

@media screen and (min-width: 560px) {
  main {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 36rem), url("../assets/herobg.jpg");
    background-attachment: fixed;
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  div#container {
    padding: 0 1rem;
  }

  section#welcome {
    background-image: none;
    background-color: transparent;
  }
}

section#about {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

section#directions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: stretch;
  gap: 1rem;
}

section#points-of-interest {
  flex-basis: 100%;
  flex-shrink: 0;
  margin: 1rem 0;
}

button {
  padding: 16px 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.18);
  border: 1px solid silver;
}

.input-address input {
  width: 100%;
  padding: 0.5rem;
}

.input-address button {
  width: 100%;
}

#map {
  flex: 1 1 100%;
  width: 100%;
  min-height: 60vh;
  background-color: silver;
}

.poi-list {
  margin-top: 1rem;
}

.poi-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  margin-bottom: 1rem;
}

.poi-item-details {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}

.poi-item img {
  max-width: 5rem;
}

#addr-search {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

#addr-search .modal-content {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

#addr-search .modal-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

#turn-by-turn {
  border: 1px solid silver;
  padding: 0.5rem;
}

#turn-by-turn.closed #map-panel {
  display: none;
}

#map-panel {
  max-height: 40vh;
  overflow: scroll;
}

#turn-by-turn .tbt-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
}

a#find-me {
  padding: 0.5rem;
  
  border: 1px solid silver;
  border-radius: var(--corder-radius);
  color: black;
  
  /* text-align: center; */

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

img.icon {
  max-width: 32px;
}

img.icon.small {
  max-width: 24px;
}

div.label {
  padding: 0 0.5rem;
  text-align: center;
}

#locations-filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  margin: 1rem 0;
}

#locations-filter a {
  padding: 0.25rem 0.75rem;
  border: 1px solid silver;
  border-radius: var(--corder-radius);

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

#locations-filter img.icon {
  margin-right: 0.25rem;
}

#location-entries {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));

  gap: 1rem;
}

.location-entry {

  padding: 1rem;
  border: 1px solid rgb(226, 226, 226);
  border-radius: var(--corder-radius);
  box-shadow: 0 2px 8px 0 rgb(218, 218, 218);
}

.location-name {
  font-weight: bold;
}

.type-all {
  color: black;
}

.location-type {
  font-size: 86%;
}

.type-tourism {
  color: green;
}

.type-guest_house {
  color: red;
}

.type-gas_station {
  color: blue;
}

.type-restaurant {
  color: orange;
}

div.funding {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (min-width: 560px) {
    div.funding {
        flex-flow: row nowrap;
    }
}

div.funding a {
    display: block;
    margin-top: 0.5rem;
}

div.funding img {
    display: block;
    max-height: 6rem;
}

section#project {
    margin: 1rem 0;
}

section#project div.funding {
    justify-content: center;
    flex-flow: row nowrap;
    margin: 1rem 0;
}